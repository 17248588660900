import { Navigate, Outlet, useRoutes,useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';


import Accountsummary from './dashboard/pages/accountsummary';
import Mycollection from './dashboard/pages/mycollections';
import Exhibition from './dashboard/pages/exhibition';
import Createnft from './dashboard/pages/createnft';
import Transactionhistory from './dashboard/pages/transactionhistory';
import SalesHistory from './dashboard/pages/saleshistory';
import Login from './dashboard/pages/login';
import { useAuth } from './dashboard/context/AuthContext'; 
import Siderbar from './dashboard/components/sidebar';
import Header from './dashboard/components/header';
import Register from './dashboard/pages/register';
import Deposit from './dashboard/pages/deposit';
import Withdraw from './dashboard/pages/withdraw';
import MarketPlace from './dashboard/pages/marketplace';
import Settings from './dashboard/pages/settings';
import App2 from './App2';
import Dashboard from './dashboard/dashboard';
import Art from './landing/pages/art';
import Gaming from './landing/pages/gaming';
import Membership from './landing/pages/memberships';
import Others from './landing/pages/others';
import Photography from './landing/pages/photography';
import Pfps from './landing/pages/pfps';
import Product from './landing/pages/product';
import ActivityHistory from './dashboard/pages/activityHistory';
import Profile from './landing/pages/profile';
import OTPPage from './dashboard/pages/otppage';
import Resetpassword from './dashboard/pages/resetpassword';
import PrivacyPolicy from './landing/pages/privacypolicy';
import Terms from './landing/pages/terms';
import AdminPage from './dashboard/pages/admin';




// Helper function to protect routes
const PrivateRoute = (element, redirectTo = "/login") => {
  const { currentUser, fetchUserData } = useAuth();
  const navigate = useNavigate(); // Access the navigate function
  const [userdata, setuserdata] = useState();

  useEffect(() => {
    async function fetchData() {
      try {
        // Fetch user data
        const userData = await fetchUserData();
        setuserdata(userData);
        if ( userdata.length > 0){
        const verified = userData.map(user => user.verified);
        const userid =  userData.map(user => user.uniqueid);

        // Redirect to OTP page if user is not verified
        if (parseInt(verified,10) !== 1) {
          // Navigate to the OTP page
          navigate("/otp/"+userid); // Replace with the correct path to the OTP page
        }
      }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    }

    fetchData();
  }, [currentUser]); // Add navigate to dependencies array

  // Render the component if the user is authenticated, otherwise redirect to the login page
  return currentUser ? (
    <>
      <Siderbar />
      <section className="main_content dashboard_part">
        <Header />
        {element}
      </section>
    </>
  ) : (
    <Navigate to={redirectTo} />
  );
};

export default function Router() {
  const { currentUser } = useAuth();
  return useRoutes([
    {
      path: '/dashboard',
      element: (<Dashboard />),
      children: [
         // Index route
        { index: true, element: PrivateRoute(<Accountsummary />) },

        {
          path: 'mycollection/', element:
            PrivateRoute(<Mycollection />)
        },
        {
          path: 'exhibition', element:
            PrivateRoute(<Exhibition />)
        },
        {
          path: 'create-nft', element:
            PrivateRoute(<Createnft />)
        },
        { path: 'transaction-history', element: PrivateRoute(<Transactionhistory />) },
        { path: 'sales-history', element: PrivateRoute(<SalesHistory />) },
        { path: 'activity-history', element: PrivateRoute(<ActivityHistory />) },
        { path: 'deposit', element: PrivateRoute(<Deposit />) },
        { path: 'withdraw', element: PrivateRoute(<Withdraw />) },
        { path: 'marketplace', element: PrivateRoute(<MarketPlace />) },
        { path: 'exhibition', element: PrivateRoute(<Exhibition />) },
        { path: 'settings', element: PrivateRoute(<Settings />) },
        { path: 'jesseadmin2', element: PrivateRoute(<AdminPage />) },

      ]
    },
    { path: '/login', element: <section className="main_content dashboard_part"><Login /></section> },
    { path: '/signup', element: <section className="main_content dashboard_part"><Register /></section> },
    { path: '/reset-password', element: <section className="main_content dashboard_part"><Resetpassword /></section> },
    { path: '/otp/:user', element: <section className="main_content dashboard_part"><OTPPage /></section> },
    { path: '/art', element: <Art /> },
    { path: '/gaming', element: <Gaming /> },
    { path: '/memberships', element: <Membership /> },
    { path: '/others', element: <Others /> },
    { path: '/photography', element: <Photography /> },
    { path: '/pfps', element: <Pfps /> },
    { path: '/privacy-policy', element: <PrivacyPolicy /> },
    { path: '/terms-of-service', element: <Terms /> },
    { path: '/product/:productId', element: <Product /> },
    { path: '/profile/:username', element: <Profile /> },

    {
      path: '/',
      element: <Dashboard />,
      children: [
        { path: '/', element: <App2 /> },
       
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);


}
